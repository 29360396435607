<template>
  <base-section id="section-consejo">
    <base-section-heading title="EL CONSEJO">
    </base-section-heading>
<!--7C99B0-->
    <v-container class="mt-n7">

      <v-row>
        <v-col>
          <v-card elevation="0">
            <v-tabs
              v-model="tab"
              centered
              show-arrows
            >
              <v-tabs-slider></v-tabs-slider>

              <v-tab href="#tab-1" class="font-weight-bold">
                Inicio
              </v-tab>

              <v-tab href="#tab-2" class="font-weight-bold">
                Historia
              </v-tab>

              <v-tab href="#tab-3" class="font-weight-bold">
                Mesa Directiva
              </v-tab>

              <v-tab href="#tab-4" class="font-weight-bold">
                Consejeros
              </v-tab>

              <v-tab href="#tab-5" class="font-weight-bold">
                Comités
              </v-tab>

              <v-tab href="#tab-6" class="font-weight-bold">
                Principios
              </v-tab>
            </v-tabs>

            <v-divider class="mt-5"></v-divider>

            <v-tabs-items v-model="tab">

              <v-tab-item value="tab-1" >
                <v-row class="mt-1">
                  <v-col>
                    <div class="base-section-heading text-center">
                      <h2 class="font-weight-bold mb-0 text-center red--text text-center mb-4" >
                        INICIO
                      </h2>
                     <!--<div class="v-responsive base-divider primary mx-auto mb-6" style="max-width: 28px;"><div class="v-responsive__content"><hr role="separator" aria-orientation="horizontal" class="v-divider theme--light"></div></div>-->
                     </div>
                  </v-col>
                </v-row>

                <v-row class="mt-1">
                  <v-col>
                    <div class="base-section-heading text-justify">
                      <p>
                        La Patología Clínica como muchas otras especialidades médicas, no tiene un origen ni fecha de nacimiento, es el conjunto de descubrimientos lo que va formando la especialidad. Surge como una especialidad médica de la fusión de diversas disciplinas que se fueron desarrollando de manera independiente de acuerdo al avance de los conocimientos y el desarrollo tecnológico.
                      </p>
                      <p>
                        La orina fue el primer fluido biológico que se utilizó para ayudar al diagnóstico, su evaluación formaba parte de la exploración del paciente tanto en Babilonia como en Egipto y la India y sus características de color, olor, volumen y consistencia era relacionada con la enfermedad. En la edad media se fabrica un recolector de orina (macula) y una carta de colores dónde se relacionaban estos con diferentes enfermedades.
                      </p>
                      <p>
                        En 1647 Thomas Willis escribe el “Tratado de las orinas” intentando ordenar en forma simple y científica las bases la uroscopia (uromancia).
                      </p>

                      <p>
                        En el siglo XVIII el Padre de la Química moderna Antonie Laurent Lavoasier contribuye al conocimiento médico científico, descubre los componentes químicos de la respiración, estableció que la respiración consiste en consumo de oxígeno y eliminación de anhídrido carbónico, midió el consumo de oxígeno durante el trabajo, la ingestión de alimentos y el reposo, con lo que se produce el nacimiento de una nueva ciencia la Química Fisiológica mas tarde llamada Bioquímica.
                      </p>
                      <p>
                        Anton van Leeuwenhoek (1632-1723) aunque comerciante en telas, publicó casi 400 artículos, descubrió los eritrocitos y su propiedad de darle color a la sangre, descubrió los espermatozoides y las bacterias, en esa misma época Robert Hooke emplea la palabra “cells” para designar pequeñas celdas que observaba bajo el microscopio en la estructura del corcho.
                      </p>
                      <p>
                        La evolución de la morfología, de la fisiología, la bioquímica y la microbiología, son los cimientos de la Patología Clínica.
                      </p>
                      <p>
                        Henry Bence Jones describe en 1847 la proteína que lleva su nombre, una globulina monoclonal que se encuentra en sangre u orina de pacientes con Mieloma.
                      </p>

                      <p>
                        Desde principios del siglo XX las pruebas de laboratorio se fueron haciendo necesarias para el diagnóstico, tratamiento y pronóstico de las enfermedades, tomando en cuenta las importantes aportaciones de Pasteur, Koch, Roux, Sorensen, Ehrlich, Folin, Metchnikoff, Landsteiner, Astrup y muchos más, que con sus descubrimientos, dieron las bases para el establecimiento de la medicina de laboratorio
                      </p>

                      <p>
                        La Patología Clínica ha sido y es un factor importante en el progreso de la medicina, en la que se aplica tecnología para realizar estudios de laboratorio, se miden constituyentes orgánicos y se identifican agentes causales de enfermedades para con estos resultados interpretar y correlacionar con las manifestaciones clínicas, apoyando el diagnóstico de las enfermedades.
                      </p>
                      <p>
                        Se requiere que el Patólogo Clínico tenga una educación formal basada en el sistema de residencias médicas con programas reconocidos por las universidades, así como una constante educación que le permitan ser una especialista competente para el desempeño responsable de esta especialidad médica.
                      </p>
                      <p>
                        El plan único de especializaciones médicas (PUEM) elaborado en la Subdivisión de Especializaciones Médicas de la División de Estudios de Posgrado e Investigación, de la Facultad de Medicina de la UNAM, con la participación de la Secretaría de Salud, Instituto Mexicano de Seguro Social y el Instituto de Seguridad y Servicios Sociales de los Trabajadores del Estado, la Academia Nacional de Medicina y la colaboración de expertos de cada especialidad y la participación del Consejo Mexicano de Patología Clínica.
                      </p>

                      <p>
                        La duración actual del curso de posgrado en Patología Clínica es de tres años. El instituto Mexicano del Seguro Social es pionero en la formación de especialistas y es la institución del Sector Salud que genera el mayor número de Patólogos Clínicos en México.
                      </p>

                    </div>

                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item value="tab-2" >
                <v-row class="mt-1">
                  <v-col>
                    <div class="base-section-heading text-center">
                      <h2 class="font-weight-bold mb-0 text-center red--text text-center mb-4" >
                        HISTORIA
                      </h2>
                      <!--<div class="v-responsive base-divider primary mx-auto mb-6" style="max-width: 28px;"><div class="v-responsive__content"><hr role="separator" aria-orientation="horizontal" class="v-divider theme--light"></div></div><!---->
                    </div>
                  </v-col>
                </v-row>

                <v-row class="mt-1">
                  <v-col>
                    <div class="base-section-heading text-justify">
                      <p>
                        El concepto de «Consejo de Especialidad» (Specialty Board) fue introducido en los Estados Unidos de Norteamérica en 1908 por el Dr. Derrick T Vail, Presidente de la Academia de Oftalmología y Otolaringología como un esfuerzo para aumentar la calidad de la medicina especializada, en 1933 se establece el American Board of Medical Specialties, es una entidad que agrupa a los Consejos de Especialidades Médicas en Estados Unidos , comprende 24 Consejos de Especialidades Médicas, con la función sustantiva de asistir a estos Consejos en el desarrollo e implementación de estándares profesionales y educacionales para la evaluación y certificación de los médicos especialistas.
                      </p>

                      <p>
                        El American Board of Pathology se funda en 1936, reconoce dos especialidades; la Patología Anatómica y la Patología Clínica, esta última con siete especialidades secundarias: Patología Química o Química Clínica, Hematopatología, Medicina Transfusional, Banco de Sangre, Microbiología Clínica, Citogenética, Genética Molecular o Patología Molecular.
                      </p>

                      <p>
                        En México los Consejos de Certificación de Médicos especialistas son cuerpos colegiados formados por prestigiados y probos representantes de la especialidad que provienen de las mas prestigiadas instituciones médicas del país para establecer el proceso de Certificación con base en los requisitos de preparación y adiestramiento en el campo de la especialidad aplicando a los nuevos médicos especialistas el examen de Certificación de conocimientos y competencias, la evaluación de la actualización de los especialistas por medio de la Recertificación periódica”. www.conacem.org.mx
                      </p>

                      <p>
                        En 1963 se inició el proceso de certificación de médicos especialistas con la fundación del Consejo Mexicano de Médicos Anatomopatólogos, hacia 1974, se habían formado 15 consejos de diferentes especialidades y estaban debidamente constituidos, la Academia Nacional de Medicina los apoyó de manera decisiva, dando orientación y constituyéndose en la entidad coordinadora y normativa, iniciando el proceso de otorgar la idoneidad a cada uno de estos consejos y auspiciando la formación de otros.
                      </p>

                      <p>
                        En la “Revista Mexicana de Laboratorio Clínico” núm. 1 Septiembre – Octubre de 1964, el editor se refiere a “la necesidad de crear un “Cuerpo Nacional de Certificación en Patología Clínica” con la misión de vigilar que los médicos Patólogos Clínicos reunieran las condiciones adecuadas para su ejercicio profesional desde el punto de vista científico, técnico y ético; y que funcione en forma independiente de las autoridades civiles y de las asociaciones gremiales.”
                      </p>

                      <p>
                        El Consejo Mexicano de Patología Clínica (COMPAC) se funda en 1974 bajo los auspicios de la Academia Nacional de Medicina.
                      </p>

                      <p>
                        En 1995 se funda el Comité Normativo Nacional de Consejos de Especialidades Médicas (CONACEM), con el objetivo de vigilar y regular el correcto funcionamiento de los Consejos de Especialidad, y reconocer la idoneidad de estos de acuerdo al cumplimiento del estatuto y dictaminar sobre la creación de otros, está formado por representantes de la Academia Nacional de Medicina, la Academia Mexicana de Cirugía y de los Consejos de Certificación de Médicos Especialistas. Existen 47 consejos que cubren todas las especialidades de la Medicina que se practican en México y a la fecha más de 90,000 médicos de todo el país han sido certificados
                      </p>

                      <p>
                        El Poder Legislativo en enero de 2006 modifica el artículo 81 de la Ley General de Salud lo que da al CONACEM la personalidad jurídica indispensable en la certificación de los médicos especialistas en la República Mexicana.
                      </p>

                      <p>
                        El CONACEM con la participación de todos los consejos estableció nuevos estatutos en noviembre de 2005 (para cumplir los requisitos solicitados por la Dirección General de Profesiones de la Secretaría de Educación Pública) los que fueron firmados con la representación de todos los consejos, a su vez cada Consejo adaptó sus estatutos a los del CONACEM, al cumplir con los criterios y requisitos de dichos estatutos cada consejo recibió la idoneidad para la certificación de los médicos especialistas.
                      </p>

                      <p>
                        Atendiendo este compromiso este Consejo se reunió para evaluar los cambios pertinentes a nuestro estatuto y estar en concordancia con el estatuto vigente del CONACEM
                      </p>
                      <p>
                        El proyecto de modificaciones fue entregado a los Consejeros asistentes a las diferentes reuniones de trabajo siendo aprobado un documento final el 17 de Marzo de 2007, el cual fue enviado a al CONACEM para su revisión y ellos nos enviaron varias sugerencias de modificación, mismas que se aprobaron por unanimidad el 26 de Mayo de 2007 en la ciudad de Aguascalientes quedando aprobado el documento definitivo.
                      </p>

                      <p>
                        El 28 de Junio de 2007 en la sede de la Academia Nacional de Medicina en reunión solemne y con la presencia de los Presidentes de la Academia Nacional de Medicina y de la Academia Mexicana de Cirugía, el Cuerpo de Gobierno del CONACEM , fueron entregados los Reconocimientos de Idoneidad a los Presidentes de los Consejos que cumplieron con el estatuto del CONACEM, este Consejo Mexicano de Patología Clínica por haber cumplido con el compromiso establecido recibió dicho reconocimiento.
                      </p>

                      <p>
                        El 1 de septiembre de 2011, el Presidente de la República publicó en el Diario Oficial de la Federación un decreto del Congreso General de los Estados Unidos Mexicanos que reforma y adiciona diversas disposiciones de la Ley General de Salud:
                      </p>

                      <p>
                        Artículo 81.- La emisión de los diplomas de especialidades médicas corresponde a las instituciones de educación superior y de salud oficialmente reconocidas ante las autoridades correspondientes. Para la realización de los procedimientos médicos quirúrgicos de especialidad se requiere que el especialista haya sido entrenado para la realización de los mismos en instituciones de salud oficialmente reconocidas ante las autoridades correspondientes.
                      </p>

                      <p>
                        El Comité Normativo Nacional de Consejos de Especialidades Médicas tendrá la naturaleza de organismo auxiliar de la Administración Pública Federal a efecto de supervisar el entrenamiento, habilidades, destrezas y calificación de la pericia que se requiere para la certificación y recertificación de la misma en las diferentes especialidades de la medicina reconocidas por el Comité y en las instituciones de salud oficialmente reconocidas ante las autoridades correspondientes. Los Consejos de Especialidades Médicas que tengan la declaratoria de idoneidad y que estén reconocidos por el Comité Normativo Nacional de Consejos de Especialidades Médicas, constituido por la Academia Nacional de Medicina de México, la Academia Mexicana de Cirugía y los Consejos de Especialidad miembros, están facultados para expedir certificados de su respectiva especialidad médica. Para la expedición de la cédula de médico especialista las autoridades educativas competentes solicitarán la opinión del Comité Normativo Nacional de Consejos de Especialidades Médicas.
                      </p>

                      <p>
                        Artículo 83.- Quienes ejerzan las actividades profesionales, técnicas y auxiliares y las especialidades médicas, deberán poner a la vista del público un anuncio que indique la institución que les expidió el Título, Diploma, número de su correspondiente cédula profesional y, en su caso, el Certificado de Especialidad vigente. Iguales menciones deberán consignarse en los documentos y papelería que utilicen en el ejercicio de tales actividades y en la publicidad que realicen al respecto.
                      </p>

                      <p>
                        Lo anterior significa el reconocimiento por parte del Congreso General de los Estados Unidos Mexicanos, (Poder Legislativo que incluye las Cámaras de Diputados y Senadores) y del Poder Ejecutivo depositado en el Presidente de la República, de la trascendente e importante tarea que realizan, el Comité Normativo Nacional de Consejos de Especialidades Médicas y los Consejos de Especialidad miembros de este comité y la obligación legal que tienen los médicos especialistas de certificarse y mantener la vigencia de su certificación
                      </p>

                      <p>
                        Los diplomas de Certificación y Recertificación son expedidos por el CONACEM y el COMPAC, los cuales han sido unificados para todas las especialidades y tiene incorporados varios elementos de seguridad.
                      </p>

                      <p>
                        La Certificación y la Recertificación periódica es una forma de proteger a los pacientes quienes son objeto y sujeto de la atención médica, ayudándoles a distinguir a los especialistas mejor preparados.
                      </p>
                    </div>

                  </v-col>
                </v-row>


              </v-tab-item>
              <v-tab-item value="tab-3" >
                <v-row class="mt-3">
                  <v-col>
                    <div class="base-section-heading text-center">
                      <h2 class="font-weight-bold mb-0 text-center red--text text-center mb-4" >
                        MESA DIRECTIVA
                      </h2>
                      <!--<div class="v-responsive base-divider primary mx-auto mb-6" style="max-width: 28px;"><div class="v-responsive__content"><hr role="separator" aria-orientation="horizontal" class="v-divider theme--light"></div></div><!---->
                    </div>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    v-for="(directivo, i) in directivos"
                    :key="'directivo_'+i"
                    v-bind="directivo"
                    md="6"
                  >
                    <v-list-item
                      :style="styles"
                      class="mb-2 px-0"
                    >
                      <v-avatar
                        class="mr-6 align-self-start mt-2"
                        color="grey lighten-3"
                        size="76"
                      >
                        <v-icon color="blue"> mdi-account</v-icon>
                      </v-avatar>

                      <v-list-item-content>

                        <div
                          class="title text-uppercase mb-2"
                          v-text="directivo.name"
                          style="color: #1E4165"
                        />

                        <v-list-item-subtitle class="mb-3 font-weight-bold red--text text--darken-1">
                          {{directivo.puesto}}
                        </v-list-item-subtitle>

                        <v-list-item-subtitle class="mb-3 font-weight-bold h6 grey--text text--darken-2">
                          {{directivo.hospital}}
                        </v-list-item-subtitle>

                        <!--<base-body
                          :text="comment"
                          space="4"
                        />-->
                        <div>
                          {{directivo.direccion}}
                        </div>
                        <div class="mt-1">
                          Tel. {{directivo.telefono}}
                        </div>
                        <div>
                          email: {{directivo.email}}
                        </div
                        ><div>

                      </div>

                        <v-divider />
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>

                <v-row class="mt-3">
                  <v-col>
                    <div class="base-section-heading text-center">
                      <h2 class="font-weight-bold mb-0 text-center red--text text-center mb-4" >
                        CONSEJEROS EMERITOS
                      </h2>

                      <!--<div class="v-responsive base-divider primary mx-auto mb-6" style="max-width: 28px;"><div class="v-responsive__content"><hr role="separator" aria-orientation="horizontal" class="v-divider theme--light"></div></div><!---->
                    </div>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    v-for="(emerito, i) in emeritos"
                    :key="'emeritos_'+i"
                    v-bind="emerito"
                    md="6"
                  >
                    <v-list-item
                      :style="styles"
                      class="mb-2 px-0"
                    >
                      <v-avatar
                        class="mr-6 align-self-start mt-2"
                        color="grey lighten-3"
                        size="76"
                      >
                        <v-icon color="blue"> mdi-account</v-icon>
                      </v-avatar>

                      <v-list-item-content>
                        <div
                          class="title text-uppercase mb-2"
                          style="color:#1E4165"
                        >
                          {{emerito.name}}
                        </div>

                        <v-list-item-subtitle class="mb-3 font-weight-bold red--text text--darken-1">
                          {{emerito.puesto}}
                        </v-list-item-subtitle>

                        <v-list-item-subtitle class="mb-3 font-weight-bold h6 grey--text text--darken-2">
                          {{emerito.hospital}}
                        </v-list-item-subtitle>

                        <!--<base-body
                          :text="comment"
                          space="4"
                        />-->
                        <div>
                          {{emerito.direccion}}
                        </div>
                        <div class="mt-1">
                          Tel. {{emerito.telefono}}
                        </div>
                        <div>
                          email: {{emerito.email}}
                        </div
                        ><div>
                      </div>
                        <v-divider />
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item value="tab-4" >
                <v-row class="mt-1">
                  <v-col>
                    <div class="base-section-heading text-center">
                      <h2 class="font-weight-bold mb-0 text-center red--text text-center mb-4" >
                        Consejeros Representando las Distintas Zonas del País
                      </h2>
                      <!--<div class="v-responsive base-divider primary mx-auto mb-6" style="max-width: 28px;"><div class="v-responsive__content"><hr role="separator" aria-orientation="horizontal" class="v-divider theme--light"></div></div><!---->
                    </div>
                  </v-col>
                </v-row>

                <v-row class="mt-1">
                  <v-col cols="12">
                    <v-row justify="center">
                      <!--<v-subheader>Zonas</v-subheader>-->

                      <v-expansion-panels >
                        <v-expansion-panel
                          v-for="(zona, i) in zonas"
                          :key="'zona_' +i"
                          hide-actions
                        >
                          <v-expansion-panel-header>
                            <v-row
                              align="center"
                              class="spacer"
                              no-gutters
                            >
                              <v-col
                              >
                                <v-avatar
                                  size="36px"
                                >
                                  <!--<img

                                    alt="Avatar"
                                    src="https://avatars0.githubusercontent.com/u/9064066?v=4&s=460"
                                  >-->
                                  <v-icon
                                    color="red"
                                    v-text="zona.icon"
                                  ></v-icon>

                                </v-avatar>
                                <strong v-html="zona.nombre"></strong>
                              </v-col>

                             <!-- <v-col
                                class="hidden-xs-only"
                                sm="5"
                                md="3"
                              >
                                <strong v-html="zona.nombre"></strong>

                              </v-col>-->

                             <!-- <v-col
                                class="text-no-wrap"
                                cols="5"
                                sm="3"
                              >
                                <v-chip
                                  v-if="zona.new"
                                  :color="`${zona.color} lighten-4`"
                                  class="ml-0 mr-2 black--text"
                                  label
                                  small
                                >
                                  {{ zona.new }} new
                                </v-chip>
                                <strong v-html="zona.title"></strong>
                              </v-col>-->

                              <!--<v-col
                                v-if="zona.excerpt"
                                class="grey--text text-truncate hidden-sm-and-down"
                              >
                                &mdash;
                                {{ zona.excerpt }}
                              </v-col>-->
                            </v-row>
                          </v-expansion-panel-header>

                          <v-expansion-panel-content>

                            <v-card-text>
                              <!-- aui el primer mapa mexico DF-->
                              <v-row>
                                <v-col cols="12" md="6">
                                  <div>
                                    <v-img
                                      :src="zona.mapa" />
                                  </div>

                                </v-col>

                                <v-col cols="12" md="6">
                                  <v-list-item
                                    :style="styles"
                                    class="mb-2 px-0"
                                  >
                                    <v-avatar
                                      class="mr-6 align-self-start mt-2"
                                      color="grey lighten-3"
                                      size="76"
                                    >
                                      <v-icon color="blue"> mdi-map-marker</v-icon>
                                    </v-avatar>

                                    <v-list-item-content>
                                      <div
                                        class="title text-uppercase mb-2"
                                        v-text=""
                                        style="color: #1E4165"
                                      >
                                        {{zona.nombre}}

                                      </div>

                                      <v-list-item-subtitle class="mb-3 font-weight-bold red--text text--darken-1">

                                      </v-list-item-subtitle>
                                      <v-list-item-subtitle class="mb-3 font-weight-bold h6 grey--text text--darken-2">
                                        <ul>
                                          <li v-for="consejero_name in zona.consejeros" :key="'consejero_zona_'+i">
                                            {{consejero_name}}
                                          </li>

                                        </ul>
                                      </v-list-item-subtitle>
                                      <v-divider class="mt-2"/>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-row>


                  </v-col>

                </v-row>
              </v-tab-item>

              <v-tab-item value="tab-5" >
                <v-row class="mt-1">
                  <v-col>
                    <div class="base-section-heading text-center">
                      <h2 class="font-weight-bold mb-0 text-center red--text text-center mb-4" >
                        COMITÉS
                      </h2>
                      <!--<div class="v-responsive base-divider primary mx-auto mb-6" style="max-width: 28px;"><div class="v-responsive__content"><hr role="separator" aria-orientation="horizontal" class="v-divider theme--light"></div></div><!---->
                    </div>
                  </v-col>
                </v-row>

                <v-row class="mt-1">
                  <v-col cols="12" md="6"
                         v-for="(comite, i) in comites"
                         :key="'comites_' + i"
                  >
                    <v-list-item
                      :style="styles"
                      class="mb-2 px-0"
                    >
                      <v-avatar
                        class="mr-6 align-self-start mt-2"
                        color="grey lighten-3"
                        size="76"
                      >
                        <v-icon color="blue"> mdi-account</v-icon>
                      </v-avatar>

                      <v-list-item-content>
                        <div
                          class="title text-uppercase mb-2"
                          v-text=""
                          style="color: #1E4165"
                        >{{comite.nombre}}
                        </div>

                        <v-list-item-subtitle class="mb-3 font-weight-bold red--text text--darken-1">
                          {{comite.titular}}
                        </v-list-item-subtitle>

                        <div>
                          email: {{comite.email}}
                        </div>

                        <v-divider class="mt-2"/>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item value="tab-6" >
                <v-row class="mt-1">
                  <v-col>
                    <div class="base-section-heading text-center">
                      <h2 class="font-weight-bold mb-0 text-center red--text text-center mb-4" >
                        DECLARACION DE PRINCIPIOS
                      </h2>
                      <!--<div class="v-responsive base-divider primary mx-auto mb-6" style="max-width: 28px;"><div class="v-responsive__content"><hr role="separator" aria-orientation="horizontal" class="v-divider theme--light"></div></div><!---->
                    </div>
                  </v-col>
                </v-row>

                <v-row class="mt-1">
                  <v-col>
                    <div class="base-section-heading text-justify">
                      <p>EL CONSEJO MEXICANO DE PATOLOGÍA CLÍNICA Y MEDICINA DE LABORATORIO DECLARA:</p>
                      <p>
                        Que el Consejo Mexicano de Patología Clínica y Medicina de Laboratorio, A.C. es una asociación integrante del Comité Normativo Nacional de Consejos de Especialidades Médicas, A. C. que ha cubierto los requisitos necesarios para que este Comité le otorgue el aval de Idoneidad para los objetivos de certificación y recertificación de médicos especialistas en Patología Clínica y Medicina de Laboratorio.
                      </p>
                      <p>
                        Que es una Asociación Civil formada por Médicos Cirujanos con especialidad en Patología Clínica y Medicina de Laboratorio con el propósito de evaluar la capacidad de los especialistas para el ejercicio de la Patología Clínica, por medio de:
                      </p>
                      <div>
                        <ol>
                          <li>La certificación a los médicos cirujanos que al termino del curso de especialización voluntariamente lo solicitan, cumpliendo los requisitos que se señalan.</li>
                          <li>La recertificación a los médicos en el ejercicio de la especialidad, voluntariamente la solicitan y demuestran de acuerdo a los requisitos establecidos que han adquirido por medio de la educación continua un mínimo de conocimientos que aseguren la actualización de sus conocimientos y destrezas para así contribuir con la certidumbre de la población que requiera sus servicios. </li>
                        </ol>
                      </div>

                      <p class="mt-3">
                        El Consejo Mexicano de Patología Clínica y Medicina de Laboratorio, A. C. está constituido por distinguidos y probos representantes del ámbito académico y asistencial de la especialidad, siendo incluyente respecto de las diferentes corrientes académicas y sus integrantes ejercen la especialidad en las mas prestigiosas instituciones públicas y privadas de salud de la República Mexicana, radican en las diversas regiones del país y provienen de las principales agrupaciones médicas no gremiales.
                      </p>

                      <p>
                        El Consejo Mexicano de Patología Clínica y Medicina de Laboratorio, A. C. mantiene una renovación periódica y gradual  de todos sus miembros asegurando la continuidad de los programas establecidos y garantizando la movilidad de todos sus directivos.
                      </p>

                      <p>
                        El Consejo Mexicano de Patología Clínica y Medicina de Laboratorio, A. C. es una agrupación autónoma en la conformación de su estructura y estatutos, que se sujeta a lo que indica el Estatuto del Comité Normativo Nacional de Consejos de Especialidades Médicas, A. C. en lo relativo a los Consejos de Certificación de Médicos Especialistas.
                      </p>

                      <p>
                        Extracto del Estatuto del CONACEM que el Consejo Mexicano de Patología Clínica y Medicina de Laboratorio, A. C. se obliga a cumplir:
                      </p>

                      <p style="font-style: italic;" class="font-weight-bold">
                        <u>
                        Artículo 10. Los requisitos que deberán cumplir los CONSEJOS para recibir la idoneidad del CONACEM, en un término no mayor de dieciocho meses a partir de la firma de los presentes estatutos, son:
                        </u>
                      </p>

                      <div>
                        <ol type="I">
                          <li>Estar constituidas como asociaciones civiles en los términos del Título Decimoprimero, Segunda Parte del Libro Cuarto del Código Civil para el Distrito Federal, o su equivalente para el caso de asociaciones constituidas en cualquier entidad federativa;</li>
                          <li class="mt-1">Tener como objeto la evaluación de la capacidad profesional para el ejercicio de la especialidad médica de que se trate, de acuerdo con el desarrollo científico, técnico o tecnológico en la materia; dictaminar la certificación y recertificación de los médicos en su especialidad; y reconocer aquellas actividades de educación médica continua y otras que apoyen la actualización médica profesional del especialista.
                            Señalar expresamente en sus Estatutos:
                          </li>
                          <div class="mt-2" >
                            <ol type="a">
                              <li style="font-style: italic"><u>Que su junta de gobierno u órgano análogo, integre a los más distinguidos y probos representantes del ámbito académico y asistencial de la especialidad y que dicho consejo será incluyente respecto de las  diferentes corrientes académicas y asistenciales de la misma originarios en su caso, de las diversas regiones del país, provenientes de las principales agrupaciones médicas no gremiales, así como de las más connotadas instituciones de salud, públicas o privadas y, cuya participación será renovada de conformidad a lo establecido en el propio consejo.</u></li>
                              <li>Que, adicionalmente, si el consejo de que se trate lo considera pertinente, invitará a tres de sus expresidentes, empezando por el más próximo, a que formen parte de una comisión consultiva de dicho consejo, la cual tendrá como propósito:</li>

                              <ul>
                                <li>Atender las consultas que le formule el presidente</li>
                                <li>En general, apoyar el cumplimiento del objeto social del consejo.</li>
                              </ul>

                              <li>
                                Que las relaciones con las autoridades de salud o educativas, ya sean federales o estatales, vinculadas con la inscripción, registro y cualquier acto análogo relativo a los certificados de especialidades y a las constancias de recertificación serán establecidas por el consejo, por conducto del CONACEM.
                              </li>

                              <li>
                                La permanencia de sus integrantes en la junta de gobierno u órgano análogo y las reglas que el propio consejo haya dispuesto para este propósito;
                              </li>

                              <li>
                                Que para ser integrante de la junta de gobierno u órgano análogo, del consejo de que se trate, será necesario que el aspirante acredite, además de lo dispuesto por sus estatutos:

                                <ul>
                                  <li>Contar con la certificación vigente de su consejo, y</li>
                                  <li>Gozar de buena reputación y fama pública.</li>
                                </ul>
                              </li>

                              <li>
                                Que acepta las resoluciones o criterios que el CONACEM haya emitido o emita respecto a la certificación y recertificación de especialistas y subespecialistas;
                              </li>
                              <li>
                                Que la aportación al CONACEM será el 7% (siete por ciento) de los ingresos que reciba el consejo respectivo por sus funciones de evaluación
                              </li>
                            </ol>
                          </div>

                          <p class="mt-3">
                            El Consejo Mexicano de Patología Clínica y Medicina de Laboratorio, A. C. establece los requisitos necesarios para la certificación y recertificación de los médicos cirujanos, especialistas en Patología Clínica así como los requisitos para la calificación de la educación continua.
                          </p>

                          <p>
                            Para ello tiene como objeto:
                          </p>

                          <div>
                            <ol type="1">
                              <li>
                                Expedir, conjuntamente con el CONACEM, los diplomas de certificación de los especialistas, egresados de las Unidades Médicas afiliadas al Comité Interinstitucional para la Formación de Recursos Humanos para la Salud de acuerdo al dictamen que emita el Comité de Certificación del Consejo Mexicano de Patología Clínica y Medicina de Laboratorio, A. C con base en los resultados de las evaluaciones aplicables
                              </li>
                              <li>
                                Expedir, conjuntamente con el CONACEM, los diplomas de recertificación de los especialistas, según el dictamen del Comité de Recertificación del Consejo Mexicano de Patología Clínica y Medicina de Laboratorio, A. C con base en los resultados de las evaluaciones aplicables.
                              </li>
                              <li>
                                Solicitar al CONACEM cada 5 años el refrendo de reconocimiento de “Idoneidad” para este Consejo al cumplir los requisitos que se soliciten.
                              </li>
                            </ol>
                          </div>

                        </ol>
                      </div>
                      <v-card class="mt-6" flat>
                          <h3 align="center" style="color: red">MISIÓN DEL CONSEJO MEXICANO DE PATOLOGÍA CLÍNICA Y MEDICINA DE LABORATORIO, A. C.</h3>

                        <div class="my-2 mx-3">

                          <div class="my-2 mx-3">
                            <p>
                              La misión del Consejo Mexicano de Patología Clínica y Medicina de Laboratorio, A. C. como miembro del Comité Normativo Nacional de Consejos de Especialidades Médicas, A. C. es la promoción de la certificación y la recertificación de los médicos especialistas en Patología Clínica por medio de las siguientes actividades:
                            </p>

                            <ol>
                              <li>Establecer los estándares para la evaluación de la certificación</li>
                              <li>Establecer los procedimientos para la revalidación de la certificación</li>
                              <li>Establecer los parámetros para la calificación de la educación continua</li>
                              <li>Establecer una comunicación continua con las unidades médicas formadoras de especialistas</li>
                              <li>Colaborar con las Universidades y unidades médicas formadoras de especialistas en la  elaboración y/o modificación de los programas académicos de la especialidad</li>
                              <li>Mantener un registro actualizado de los especialistas certificados y revalidados en Patología Clínica</li>
                              <li>Colaborar con la autoridad en la información de Patología Clínica a través del CONACEM</li>
                            </ol>
                          </div>

                          <p class="mt-2">
                            El Certificado que el Comité Normativo Nacional de Consejos de Especialidades Médicas, A. C. y el Consejo Mexicano de Patología Clínica y Medicina de Laboratorio, A. C. otorga es un reconocimiento al que lo obtiene, que demostró poseer los conocimientos y habilidades indispensables en el área de competencia y que ha obtenido después de una preparación planificada
                          </p>

                          <p>
                            El certificado:
                          </p>

                          <p>
                            NO es un grado académico
                          </p>
                          <p>
                            NO confiere autorización para el ejercicio de la especialidad
                          </p>
                          <p>
                            NO define privilegios como especialista
                          </p>
                          <p>
                            NO define que debe ser contratado para la práctica de la Patología Clínica
                          </p>
                          </div>
                      </v-card>
                    </div>

                  </v-col>
                </v-row>
              </v-tab-item>


            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>

    </v-container>
  </base-section>
</template>

<script>

  export default {
    name: 'SectionConsejo',
    data: () => ({
      lorem:'khjgkjhkjhk',
      zonas: [
        {
          avatar: 'https://avatars0.githubusercontent.com/u/9064066?v=4&s=460',
          icon: 'mdi-tag',
          nombre: 'ZONA METROPOLITANA DE LA CIUDAD DE MÉXICO',
          mapa: require('@/assets/compac/mapa/mexico_df.png'),
          consejeros:['Dra. Roxana Blanca Rivera Leaños','Dr. Francisco de Jesús Sánchez Girón' ]
        },
        {
          color: 'red',
          icon: 'mdi-tag',
          nombre: 'ZONA NORESTE',
          mapa: require('@/assets/compac/mapa/mexico_noreste.png'),
          consejeros:['Dr. Felipe Mercado del Ángel','Dr. Carlos Gabriel Díaz Olachea' ]

        },
        {
          color: 'teal',
          icon: 'mdi-tag',
          nombre: 'ZONA NOROESTE',
          mapa: require('@/assets/compac/mapa/mexico_noroeste.png'),
          consejeros:['Dr. Abraham Jesús González Olivos']

        },
        {
          color: 'teal',
          icon: 'mdi-tag',
          nombre: 'ZONA SUR',
          mapa: require('@/assets/compac/mapa/mexico_sur.png'),
          consejeros:['Dr. Álvaro Manuel Machorro Castaños', 'Dr. Rafael Guerrero García']

        },
        {
          color: 'teal',
          icon: 'mdi-tag',
          nombre: 'ZONA CENTRO-OCCIDENTE',
          mapa: require('@/assets/compac/mapa/mexico_centro.png'),
          consejeros:['Dra. Rosa Magaly Gómez Gutiérrez','Dr. Felipe De Jesús Herrera Gómez']

        },
      ],

      directivos: [
        {
          name: 'Dr. CARLOS GABRIEL DIAZ OLACHEA',
          puesto:'PRESIDENTE',
          hospital:'Hospital San José - TecSalud',
          direccion:'Hidalgo Poniente No. 2306, Monterrey, Nuevo León. CP.64060',
          telefono: '81-8348-8312',
          email: 'carlos.diaz@compac.org.mx',
          reply: true,
          src: require('@/assets/user-2.jpg'),
        },
        {
          name: 'Dr. Rafael Guerrero García',
          puesto:'VICEPRESIDENTE',
          hospital:'Laboratorios Guerrero',
          direccion:'Circuito Rafael Guizar y Valencia # 731 Xalapa, Veracruz. C.P. 91097',
          telefono: '22-8814-1646',
          email: 'rafael.guerrero@compac.org.mx',
          reply: true,
          src: require('@/assets/user-2.jpg'),
        },
        {
          name: 'Dra. Rosa Magaly Gómez Gutiérrez',
          puesto:'SECRETARIA',
          hospital:'Unidad de Patología Clínica',
          direccion:'Av. México No. 2341, Guadalajara, Jalisco. C.P. 44650',
          telefono: ' 33-3669-0310',
          email: 'magaly.gomez@compac.org.mx',
          reply: true,
          reply: false,
          src: require('@/assets/user-1.jpg'),
        },

        {
          name: 'Dr. Gilberto Aguilar Orozco',
          puesto:'TESORERO',
          hospital:'Hospital Aranda de la Parra',
          direccion:'Hidalgo No. 329 León, Guanajuato. C.P. 37000',
          telefono: '47-7713-3303',
          email: 'gilberto.aguilar@compac.org.mx',
          reply: true,
          reply: false,
          src: require('@/assets/user-3.jpg'),
        },
      ],
      size:56,
      tab: 'tab-3',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      emeritos: [
        {
          name: 'Dr. Gilberto Aguilar Orozco',
          puesto:'',
          hospital:'Hospital Aranda de la Parra',
          direccion:'Hidalgo No. 329, C. P. 37000, León, Guanajuato.',
          telefono: '47-7713-3303',
          email: 'gilberto.aguilar@compac.org.mx',
          reply: true,
          src: require('@/assets/user-2.jpg'),
        },
        {
          name: 'Dr. Jesús Ignacio Simón Domínguez',
          puesto:'',
          hospital:'',
          direccion:'Ximilpa No. 31-4, C.P. 14000, México, D.F.',
          telefono: '55-5655-0198',
          email: 'jesus.simon@compac.org.mx',
          reply: true,
          src: require('@/assets/user-2.jpg'),
        },
        {
          name: 'Dr. Luis Alberto Santoscoy Tovar',
          puesto:'',
          hospital:'Unidad de Patología Clínica',
          direccion:'Av. México No. 2341, C.P. 44650, Guadalajara, Jalisco.',
          telefono: '33-3669-0310',
          email: 'luis.santoscoy@compac.org.mx',
          reply: true,
          src: require('@/assets/user-2.jpg'),
        },
        {
          name: 'Dr. Guillermo José Santoscoy Tovar',
          puesto:'',
          hospital:'Unidad de Patología Clínica',
          direccion:'Av. México No. 2341, C.P. 44650, Guadalajara, Jalisco.',
          telefono: '33-3669-0310',
          email: 'guillermo.santoscoy@compac.org.mx',
          reply: true,
          src: require('@/assets/user-2.jpg'),
        },
        {
          name: 'Dr. José Ramírez Barragán',
          puesto:'',
          hospital:'Hospital Ángeles del Carmen',
          direccion:'Guadajalara, Jalisco',
          telefono: '33-3813-0042',
          email: 'jose.ramirez@compac.org.mx',
          reply: true,
          src: require('@/assets/user-2.jpg'),
        },
      ],
      comites:[
        {
          nombre: 'CERTIFICACIÓN',
          titular:'Dr. Luis Alberto Santoscoy Tovar',
          email:'certificación@compac.org.mx'
        },
        {
          nombre: 'RECERTIFICACIÓN',
          titular:'Dr. Felipe Mercado Del Ángel',
          email:'recertificacion@compac.org.mx'
        },
        {
          nombre: 'EDUCACIÓN MÉDICA CONTINUA',
          titular:'Dr. Abraham González Olivos',
          email:'agonzalezolivos@gmail.com'
        },
        {
          nombre: 'RESIDENCIAS',
          titular:'Dra. Roxana Blanca Rivera Leaños',
          email:'roxana24@gmail.com'
        },
        {
          nombre: 'ESTATUTO Y REGLAMENTOS',
          titular:'Dr. Rafael Guerrero García',
          email:'rafael.guerrero@compac.org.mx'
        },
        {
          nombre: 'ÉTICA',
          titular:'Dr. Francisco de Jesús Sánchez Girón',
          email:'francisco.sanchez@medicasur.org.mx'
        }
      ]
    }),
    computed: {
      classes () {
        return [
          this.outlined && 'base-avatar--outlined',
        ]
      },
      classes2 () {
        return [
          `text-${this.align}`,
          `mb-${this.space}`,
        ]
      },
      isDark () {
        return this.theme.isDark || this.dark
      },
      outlineSize () {
        return Number(this.size) + (this.size / this.multiply)
      },
      styles () {
        const margin = this.size / (this.multiply * 2)

        return {
          // Aligns the outline content with the content
          margin: `-${margin}px 0 0 -${margin}px`,
        }
      },
    },
  }
</script>

<style lang="sass">
  .base-avatar
    border-radius: 50%
    position: relative
    overflow: visible

    &__outline
      position: absolute !important
      left: 0
      top: 0

    &.base-avatar--outlined .base-avatar__avatar
      border-width: thin
      border-style: solid

    .theme--light .base-avatar--outlined .base-avatar__avatar
      border-color: rgba(0, 0, 0, .12) !important

    .theme--dark .base-avatar--outlined .base-avatar__avatar
      border-color: rgba(255, 255, 255, .12) !important
</style>
